<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page :zIndex="2147483639"></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Company Form</h1>
          </div>
          <div class="page-content">
            <form v-on:submit.prevent="onSubmit" @keydown.enter.prevent>
              <div class="alert alert-danger alert-validation-summary-errors" v-if="errors.any()">
                <button type="button" class="close" data-dismiss="alert">×</button>
                <strong class="alert-heading">Please review the following errors</strong>
                <ul>
                  <li v-for="error in errors.all()" :key="error" class="error-message">{{ error }}</li>
                </ul>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">1</span>
                    <h2 class="m-0">Company Information</h2>
                  </div>
                  <div class="row d-flex justify-content-end align-items-center">
                    <span class="red-dot mr-2"></span> = Required
                  </div>
                  <company-information :company.sync="company" @updateAlias="updateAlias"></company-information>
                  <company-memberships></company-memberships>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">2</span>
                    <h2 class="m-0">User Information</h2>
                  </div>
                  <company-user-information :company.sync="company"></company-user-information>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">3</span>
                    <h2 class="m-0">Business Category</h2>
                  </div>

                  <label class="font-weight-bold">Select Business Category <span class="red-dot ml-3"></span> </label>
                  <div id="categoryBusiness">
                    <div v-for="(category, index) in getBusinessCategories" :key="'business-category-container-' + index" class="custom-control custom-checkbox">
                      <input :id="'business-category-item-' + index" name="businessCategory" type="checkbox"
                             class="custom-control-input" :class="{ 'is-invalid': errors.has('businessCategory') }"
                             :value="category.id" v-model="company.businessCategoryIds" v-if="index === 0" v-validate="'required'" data-vv-as="Business Category"
                             @change="onBusinessCategorySelected(category.id, $event.target.checked)">
                      <input :id="'business-category-item-' + index" name="businessCategory" type="checkbox"
                             class="custom-control-input" :class="{ 'is-invalid': errors.has('businessCategory') }"
                             :value="category.id" v-model="company.businessCategoryIds" v-else
                             @change="onBusinessCategorySelected(category.id, $event.target.checked)">
                      <label class="custom-control-label" :for="'business-category-item-' + index">{{ category.name }}</label>
                      <div v-if="index === getBusinessCategories.length - 1" class="invalid-feedback">Please select at least one Business Category</div>
                    </div>
                  </div>
                </div>
                <div class="col-md d-none d-lg-block"></div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">4</span>
                    <h2 class="m-0">Service Region</h2>
                  </div>

                  <label>Select Service Region <span class="ml-3"></span> </label>
                  <div id="serviceRegion">
                    <div v-for="(serviceRegion, index) in getServiceRegions" :key="'service-region-container-' + index" class="custom-control custom-checkbox">
                      <input :id="'service-region-item-' + index" name="serviceRegion" type="checkbox"
                             class="custom-control-input"
                             :value="serviceRegion.id"
                             v-model="company.serviceRegionIds">
                      <label class="custom-control-label" :for="'service-region-item-' + index">{{ serviceRegion.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md d-none d-lg-block"></div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">5</span>
                    <h2 class="m-0">Labour Type</h2>
                  </div>

                  <label>Select Labour Type</label>

                  <div v-for="(labourType, index) in getLabourTypes" :key="'labour-type-container-' + index" class="custom-control custom-radio">
                    <input :id="'labour-type-item-' + index" name="labourType" type="radio" class="custom-control-input"
                           :class="{ 'is-invalid': errors.has('labourType') }" :value="labourType.id" v-model="company.labourTypeId"
                           v-if="index === 0" data-vv-as="Labour Type">
                    <input :id="'labour-type-item-' + index" name="labourType" type="radio" class="custom-control-input"
                           :class="{ 'is-invalid': errors.has('labourType') }" :value="labourType.id" v-model="company.labourTypeId"
                           v-else>
                    <label class="custom-control-label" :for="'labour-type-item-' + index">{{ labourType.name }}</label>
                    <div v-if="index === getLabourTypes.length - 1" class="invalid-feedback">Please a labour type</div>
                  </div>

                  <div id="company-labour-of-works-container" v-if="company.labourTypeId && getLabourOfWorks.length > 0">
                    <div v-for="(work, index) in getLabourOfWorks" :key="'labour-of-works-container-' + index" class="custom-control custom-checkbox">
                      <input :id="'labour-of-works-item-' + index" type="checkbox" class="custom-control-input" :value="work.id" v-model="company.labourOfWorkIds">
                      <label class="custom-control-label" :for="'labour-of-works-item-' + index">{{ work.name }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">6</span>
                    <h2 class="m-0">Classification of Work</h2>
                  </div>

                  <div class="form-group">
                    <label for="classificationOfWork">Classification of Work <span class="red-dot ml-3"></span></label>
                    <v-select id="classificationOfWork" name="classificationOfWork" placeholder="Select applicable classifications of work"
                              class="v-select-multi" :class="{ 'is-invalid': errors.has('classificationOfWork') }"
                              multiple :closeOnSelect="false" label="name" :reduce="cow => cow.id" :options="getClassificationOfWork" v-model="company.classificationOfWorkIds"
                              v-validate="'required'" data-vv-as="Classification of Work">
                    </v-select>
                    <div class="invalid-feedback">The Classification of Work field is required</div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">7</span>
                    <h2 class="m-0">Owner Type You Work For</h2>
                  </div>

                  <div class="form-group">
                    <label for="ownerType">Owner Type <span class="red-dot ml-3"></span></label>
                    <v-select id="ownerType" name="ownerType" placeholder="Select applicable owner types"
                              class="v-select-multi" :class="{ 'is-invalid': errors.has('ownerType') }"
                              multiple :closeOnSelect="false" label="name" :reduce="cow => cow.id" :options="getOwnerTypes" v-model="company.ownerTypeIds"
                              v-validate="'required'" data-vv-as="Owner Type">
                    </v-select>
                    <div class="invalid-feedback">The Owner Type field is required</div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">8</span>
                    <h2 class="m-0">Categories</h2>
                  </div>

                  <div class="row ml-1">
                    <label class="d-flex align-items-center font-weight-bold" for="CategoryIds">Categories</label>
                  </div>

                  <div class="row treeviewContainer  ml-1">
                    <treeselect v-model="company.categoryIds" :searchable="false" :multiple="true" :options="categories" :load-options="loadOptions"
                                search-nested :flat="true" :cacheOptions="false" />
                  </div>
                  <div class="form-group mt-3">
                    <label for="matchingKeywords">Matching Keywords</label>
                    <v-select id="matchingKeywords" name="matchingKeywords" placeholder="Add a matching keyword" no-drop multiple taggable selectOnTab
                              class="v-select-multi" :closeOnSelect="false" label="name" v-model="company.matchingKeywords">
                    </v-select>
                    <small class="form-text text-muted">
                      Enter keyword or product name into the input and press Enter/Return on your keyboard to add.
                    </small>
                  </div>

                  <div class="form-group" v-if="showProductMatchingKeywords">
                    <label for="productMatchingKeywords">Product Matching Keywords</label>
                    <v-select id="productMatchingKeywords" name="productMatchingKeywords" placeholder="Add a product matching keyword" no-drop multiple taggable selectOnTab
                              class="v-select-multi" :closeOnSelect="false" label="name" v-model="company.productMatchingKeywords">
                    </v-select>
                    <small class="form-text text-muted">
                      Enter keyword or product name into the input and press Enter/Return on your keyboard to add.
                    </small>
                  </div>


                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-md-12">
                  <div class="row form-title d-flex align-items-center mb-4">
                    <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">9</span>
                    <h2 class="m-0">Notifications and Dashboard Settings</h2>
                  </div>

                  <div class="row ml-1">
                    <p> Use this section to customize the projects that appear in your nightly opportunity email and dashboard. </p>
                    <p> Select which zones you would like to see on your dashboard and be emailed about. Also when you visit the project search page,
                      the zone selection will be defaulted to have the zones below selected. You can still use the project search to find and view projects
                      that are in the zones you have selected.</p>
                  </div>

                  <div v-if="getZones.length" class="mt-1 ml-1">
                    <div v-for="zone in getZones" :key="'company-target-zone-' + zone.id" class="custom-control custom-checkbox">
                      <input :id="'service-region-item-' + zone.id" name="serviceRegion" type="checkbox" class="custom-control-input"
                        :value="zone.id" v-model="company.targetedZoneIds" />
                      <label :for="'service-region-item-' + zone.id" class="custom-control-label">{{ zone.name }}</label>
                    </div>
                  </div>
                  <div v-else class="alert alert-warning" role="alert">
                    No zones available
                  </div>
                </div>
                <div class="col-md d-none d-lg-block"></div>
              </div>

              <hr />

              <div style="display:block;">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row form-title d-flex align-items-center mb-4">
                      <span class="rounded-circle bg-primary mr-2 d-flex justify-content-center align-items-center text-white">10</span>
                      <h2 class="m-0">Public Directory</h2>
                    </div>
                    <div id="publicDirectory">
                      <div class="row">
                        <div class="col align-left text-left">
                          <div class="custom-control custom-checkbox">
                            <input id="publicDirectoryCheckBox" type="checkbox"
                                   class="custom-control-input"
                                   v-model="company.showInPD">
                            <label class="custom-control-label" for="publicDirectoryCheckBox">Show company in public directory</label>
                          </div>
                        </div>
                        <div class="col">
                          <label for="alias"><strong>Company Alias:</strong></label><span class="ml-2" id="alias">{{company.alias}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md d-none d-lg-block"></div>
                </div>


                <hr />

                <div class="row">
                  <div class="col-md-12">
                    <CompanyCertificationsBonding :isEdit="isEdit" :company.sync="company"></CompanyCertificationsBonding>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="d-flex align-items-center font-weight-bold" for="businessDescription">Business Description</label>

                      <textarea id="businessDescription" name="businessDescription" type="text" class="form-control" :class="{ 'is-invalid': errors.has('businessDescription') }"
                                autocomplete="off" placeholder="Business Description" v-model="company.businessDescription"
                                rows="4" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <CompanyAssociations></CompanyAssociations>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-12">
                    <CompanyProjectSamples></CompanyProjectSamples>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-12">
                    <CompanySocialMediaInformation></CompanySocialMediaInformation>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <CompanyLicenceInformation></CompanyLicenceInformation>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="d-flex align-items-center font-weight-bold" for="unionAffiliation">Union Affiliations</label>

                      <v-select id="unionAffiliation" name="unionAffiliation" placeholder="Select union affiliations"
                                class="v-select-multi" :class="{ 'is-invalid': errors.has('unionAffiliation') }"
                                multiple :closeOnSelect="false" label="name" :reduce="cow => cow.id" :options="getUnionAffiliations" v-model="company.unionAffiliationIds"
                                data-vv-as="Union affiliation"
                                aria-label="Union Affiliations">
                      </v-select>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-6">
                    <h3>Company Logo</h3>
                    <UploadFile id="logo" type="image" displayName="Company Logo" :acceptTypes="['jpg', 'jpeg', 'png']"
                      :existingFile.sync="company.logo" @saveFile="saveLogo"></UploadFile>
                  </div>
                </div>
              </div>
            </form>
            <hr />
            <div class="mt-3 mb-3 d-flex ">
              <div class="flex-fill ">
                <div class="d-flex flex-column  mb-3">
                  <div class="">Created By: {{ company.createdBy | fullname }}</div>
                  <div class="">Created On: {{ company.createdOn | datetime('MMMM dd, yyyy h:mmaa') }}</div>
                  <div class="">Last Updated By: {{ company.updatedBy | fullname }}</div>
                  <div class="">Last Updated On: {{ company.updatedOn | datetime('MMMM dd, yyyy h:mmaa') }}</div>
                </div>
              </div>
              <div class="p-2 flex-fill ">&nbsp;</div>
              <div class="p-2 flex-fill ">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-bar-wrapper" class="d-print-none">
      <div>
        <button type="submit" class="save-bar-Button ld-ext-right" :class="{ 'running' : isSaving }" @click="onSubmit()" :disabled="isSaving">
          <span v-if="isSaving">Saving...</span>
          <span v-else>Save</span>
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="submit" class="save-bar-Button ml-2" @click="goToTop()">
          <i class="fas fa-arrow-alt-circle-up"></i>
          Back to Top
        </button>
      </div>
    </div>
  </fragment>
</template>

<script>
  import Toast from "@/utils/toast";
  import { alpha as alphaSort } from "@/utils/sort";
  import { mapGetters, mapActions } from "vuex";
  import { CategoryService, CompanyService } from "@/services/";

  import AppNavBar from "@/components/app-navbar.vue";
  import CompanyInformation from "@/views/company/components/company-information.vue";
  import CompanyMemberships from "@/views/company/components/company-memberships.vue";
  import CompanyUserInformation from "@/views/company/components/company-user-information.vue";
  import CompanyAssociations from "@/views/company/components/company-associations.vue";
  import CompanyProjectSamples from "@/views/company/components/company-project-samples.vue";
  import CompanySocialMediaInformation from "@/views/company/components/company-social-media-information.vue";
  import CompanyLicenceInformation from "@/views/company/components/company-licence-information.vue";
  import CompanyCertificationsBonding from "@/views/company/components/company-certifications-bonding.vue";
  import Treeselect from "@riophae/vue-treeselect";
  import UploadFile from "@/components/upload-file.vue";

  import "@riophae/vue-treeselect/dist/vue-treeselect.css";

  const BUSINESS_CATEGORY_MANUFACTURER_SUPPLIER = "e3d7f0ee-6def-40b5-8693-e8419837a6d3";

  export default {
    components: {
      "app-navbar": AppNavBar,
      CompanyInformation,
      CompanyMemberships,
      CompanyUserInformation,
      CompanyAssociations,
      CompanyProjectSamples,
      CompanySocialMediaInformation,
      CompanyLicenceInformation,
      CompanyCertificationsBonding,
      Treeselect,
      UploadFile
    },
    provide() {
      return {
        $validator: this.$validator
      };
    },
    data: function () {
      return {
        company: {},
        isSaving: false,
        isPageLoading: true,
        isEdit: false,
        showProductMatchingKeywords: false,
        categories: []
      };
    },
    computed: {
      ...mapGetters([
        "getBusinessCategories",
        "getClassificationOfWork",
        "getCompany",
        "getCompanyMemberships",
        "getGroupedCategories",
        "getLabourTypes",
        "getOwnerTypes",
        "getProvinces",
        "getRoles",
        "getServiceRegions",
        "getZones",
        "getLicences",
        "getUnionAffiliations"
      ]),
      getLabourOfWorks() {
        if (this.company.labourTypeId) {
          let selectedLabourType = this.getLabourTypes.find(
            type => type.id === this.company.labourTypeId
          );
          if (selectedLabourType) {
            return selectedLabourType.labourOfWorks;
          }
        }
        return [];
      }
    },
    methods: {
      ...mapActions([
        "fetchBusinessCategories",
        "fetchLabourTypes",
        "fetchClassificationOfWork",
        "fetchCompany",
        "fetchCompanyAssociation",
        "fetchRoles",
        "fetchGroupedCategories",
        "fetchProvinces",
        "fetchOwnerTypes",
        "fetchServiceRegions",
        "fetchZones",
        "saveCompany",
        "resetCompany",
        "fetchLicences",
        "fetchSocialMediaTypes",
        "fetchUnionAffiliations",
      ]),
      addMatchingKeyword(tag) {
        this.company.matchingKeywords.push(tag);
      },
      addProductMatchingKeyword(tag) {
        this.company.productMatchingKeywords.push(tag);
      },
      goToTop() {
        window.scrollTo(0, 0);
      },
      onBusinessCategorySelected(id, value) {
        if (id === BUSINESS_CATEGORY_MANUFACTURER_SUPPLIER) {
          this.showProductMatchingKeywords = value;
        }
      },
      async loadOptions({ action, parentNode, callback }) {
        if (action === 'LOAD_CHILDREN_OPTIONS') {
          let childData = await CategoryService.getLevelByParentId(parentNode.id);
          parentNode.children = childData.data.map(i => this.normalizeCategoryData(i));
          callback();
        }
      },
      normalizeCategoryData(node) {
        let category = { id: node.id, label: node.code + ' - ' + node.name };
        if (node.hasChildren) {
          category.children = node.children.map(i => this.normalizeCategoryData(i)).sort((a, b) => alphaSort(a.label, b.label));
        }
        return category;
      },
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          window.scrollTo(0, 0);
          return;
        }

        if (this.showProductMatchingKeywords == false) {
          this.company.productMatchingKeywords = [];
        }

        this.isSaving = true;

        this.saveCompany({ data: this.company, isEdit: this.isEdit })
          .then(company => {
            this.company = company;
            Toast.success(this, "Successfully saved!");
            this.isSaving = false;

            if (this.isEdit === false) {
              this.$router.push("/company/edit/" + company.id);
            }
          })
          .catch(() => {
            Toast.danger(this, "Oops! There was an error.");
            this.isSaving = false;
          });

      },
      setAlias(aliasFromCompany) {
        this.alias = aliasFromCompany;

        if ((this.alias || '').length == 0) {
          this.updateAlias();
        }
      },
      updateAlias() {
        CompanyService.getAlias(this.company).then(result => {
          this.company.alias = result.data;
        });
      },
      saveLogo(uploadedFile) {
        CompanyService.saveCompanyFile(uploadedFile, this.company.id, 'logo').then(response => this.company.logo = response.data);
      }
    },
    mounted: function () {
      Promise.all([
        this.fetchBusinessCategories(),
        this.fetchLabourTypes(),
        this.fetchClassificationOfWork(),
        CategoryService.getTopLevel(),
        this.fetchOwnerTypes(),
        this.fetchProvinces(),
        this.fetchRoles(),
        this.fetchServiceRegions(),
        this.fetchZones(),
        this.fetchCompany(this.$route.params.id),
        this.fetchLicences(),
        this.fetchSocialMediaTypes(),
        this.fetchUnionAffiliations()
      ]).then(resp => {
        this.categories = resp[3].data.map(i => this.normalizeCategoryData(i)).sort((a, b) => alphaSort(a.label, b.label));

        this.company = this.getCompany;
        this.isPageLoading = false;
        this.isEdit = !!this.$route.params.id;
        this.showProductMatchingKeywords = this.getCompany.businessCategoryIds.indexOf(BUSINESS_CATEGORY_MANUFACTURER_SUPPLIER) !== -1;
      })
        .catch(err => {
          if (err && err.response && err.response.data && err.response.data.message) {
            let message = this.$createElement('span', {}, ['Oops! You managed to get to an area you do not have permission to visit', this.$createElement('br'), 'Redirecting back to dashboard']);
            Toast.danger(this, [message], { autoHideDelay: 7500 });

            this.$router.push('/dashboard');
            return;
          }

          this.isPageLoading = false;
          let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
          Toast.danger(this, [message], { autoHideDelay: 7500 });
        });
    }
  };
</script>
